import { NeuronCore } from "@sphtech/neuron-core";
import NeuronCorePlugin from "@sphtech/neuron-plugin-core";
import NeuronBrowserPlugin from "@sphtech/neuron-plugin-browser";
import NeuronCavaiAdsPlugin from "@sphtech/neuron-plugin-cavaiads";
import defaultConfig from "./config/default.config.json";

const neuronWebSDK = globalThis.NeuronWebSDK ?? {};

globalThis.NeuronWebSDK = {
  ...neuronWebSDK,
  bundle: {
    version: _neuronVersion ?? "unknown",
  },
};

const Neuron = new NeuronCore();

Neuron.registerPlugin(NeuronCorePlugin);
Neuron.registerPlugin(NeuronBrowserPlugin);
Neuron.registerPlugin(NeuronCavaiAdsPlugin);
Neuron.setConfig(defaultConfig);

export default Neuron;
