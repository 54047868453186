{
  "modules": [
    {
      "plugin": "browser",
      "name": "Browser"
    },
    {
      "plugin": "core",
      "name": "Cache"
    },
    {
      "plugin": "browser",
      "name": "SessionStorage",
      "identifier": "sessionStorage",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "LocalStorage",
      "identifier": "localStorage",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "Publication",
      "args": [
        {
          "browser": "Browser",
          "domainToPublicationMap": {
            "straitstimes.com": "st_sg",
            "businesstimes.com.sg": "bt_sg",
            "zaobao.com.sg": "zb_sg",
            "zaobao.com": "zb_cn",
            "tamilmurasu.com.sg": "tm_sg",
            "beritaharian.sg": "bh_sg",
            "thinkchina.sg": "thinkchina_sg",
            "eshop.crsm.dev.ems.sphdigital.com.sg": "eshop_sg",
            "uattmp.eshop4-cms.sph.com.sg": "eshop_sg",
            "contest.sph.com.sg": "contest_sg",
            "contest-frontend-uat.sph.com.sg": "contest_sg",
            "subscribe.sph.com.sg": "eshop_sg",
            "sphmagazines.com.sg": "utm",
            "femalemag.com.sg": "female",
            "iconsingapore.com": "iconsingapore",
            "forums.hardwarezone.com.sg": "hwzforums",
            "hardwarezone.com.sg": "hwz",
            "harpersbazaar.com.sg": "hpz",
            "e-paper.sph.com.sg": "epaper"
          }
        }
      ]
    },
    {
      "plugin": "core",
      "name": "transformers.schemas.v1.NeuronDataTransformer",
      "identifier": "addNeuronData"
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.GA3_ArticleMetadataTransformer",
      "identifier": "addArticleMetadata",
      "args": [
        {
          "browser": "Browser",
          "cache": "Cache"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.UTMMetadataTransformer",
      "identifier": "addUTMMetadata",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.DeviceMetadataTransformer",
      "identifier": "addDeviceMetadata",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.IdentityTransformer",
      "identifier": "addIdentity",
      "args": [
        {
          "storage": "cookie"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.AddViewIdentifierTransformer",
      "identifier": "addViewId",
      "args": [
        {
          "viewId": "ViewId"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.VisitIdTransformer",
      "identifier": "addVisitId",
      "args": ["sessionStorage"]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.LinkDecorationTransformer",
      "identifier": "addIncomingNeuronId",
      "args": ["sessionStorage"]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.PermutiveTransformer",
      "identifier": "addPermutive",
      "args": ["localStorage"]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.AdImpressionSchemaTransformer",
      "identifier": "applyAdImpressionSchema"
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.ElementVisibleSchemaTransformer",
      "identifier": "applyElementVisibleSchema",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.ClickSchemaTransformer",
      "identifier": "applyClickSchema",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.AdClickSchemaTransformer",
      "identifier": "applyAdClickSchema"
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.ViewTimeSchemaTransformer",
      "identifier": "applyViewTimeSchema",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.ScrollDepthSchemaTransformer",
      "identifier": "applyScrollDepthSchema",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.schemas.v1.GTMDataLayerSchemaTransformer",
      "identifier": "applyGTMDataLayerSchema"
    },
    {
      "plugin": "core",
      "name": "transformers.EventToPayloadTransformer",
      "identifier": "transformEventToPayload"
    },
    {
      "plugin": "browser",
      "name": "transformers.HighwayPayloadTransformer",
      "identifier": "applyHighwayPayloadSchema",
      "args": [
        {
          "publication": "Publication",
          "highwayToken": "HighwayToken",
          "neuronIdentifier": "NeuronIdentifier"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.ChangePauseToUnloadTransformer",
      "identifier": "changePauseToUnload"
    },
    {
      "plugin": "browser",
      "name": "transformers.FlushSourceToPayloadTransformer",
      "identifier": "flushSourceToPayload",
      "args": [
        {
          "source": "buffer"
        }
      ]
    },
    {
      "plugin": "core",
      "name": "transformers.ApplyToPayloadEventsTransformer",
      "identifier": "applyToPayloadEvents",
      "args": [
        {
          "transformers": ["addIdentity"]
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "Cookie",
      "identifier": "cookie"
    },
    {
      "plugin": "core",
      "name": "Buffer",
      "identifier": "buffer"
    },
    {
      "plugin": "browser",
      "name": "HighwayApiUrlConstructor",
      "args": ["var:sessionApiUrl", "var:sessionApiToken"]
    },
    {
      "plugin": "browser",
      "name": "Post",
      "identifier": "post",
      "args": [
        {
          "browser": "Browser",
          "urlConstructor": "HighwayApiUrlConstructor"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "Beacon",
      "identifier": "beacon",
      "args": [
        {
          "browser": "Browser",
          "urlConstructor": "HighwayApiUrlConstructor"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "HighwayToken",
      "args": [
        {
          "storage": "sessionStorage"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "HighwayTokenCallbackTask",
      "args": [
        {
          "highwayToken": "HighwayToken"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "transformers.AttachCallbackTransformer",
      "identifier": "attachHighwayTokenCallback",
      "args": [
        {
          "instance": "HighwayTokenCallbackTask"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "ViewtimeInitialTimestamp",
      "args": [
        {
          "storage": "sessionStorage"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "ClearViewtimeTimestampTask",
      "args": [
        {
          "viewtimeInitialTimestamp": "ViewtimeInitialTimestamp"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "SetViewtimeTimestampTask",
      "args": [
        {
          "viewtimeInitialTimestamp": "ViewtimeInitialTimestamp"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "NeuronIdentifier",
      "args": [
        {
          "storage": "cookie"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "ViewId"
    },
    {
      "plugin": "core",
      "name": "SubTask",
      "identifier": "onLoadAndResumeSubTask",
      "args": [
        {
          "tasks": ["TransformTask", "SetViewtimeTimestampTask"]
        }
      ],
      "subscriptions": ["TrackLoadTask", "TrackResumeTask"]
    },
    {
      "plugin": "core",
      "name": "SubTask",
      "identifier": "onPauseSubTask",
      "args": [
        {
          "tasks": ["TransformTask", "ClearViewtimeTimestampTask"]
        }
      ],
      "subscriptions": ["TrackPauseTask"]
    },
    {
      "plugin": "core",
      "name": "SendToDestinationTask",
      "identifier": "sendToBufferTask",
      "args": ["buffer"],
      "subscriptions": ["transformPauseTask"]
    },
    {
      "plugin": "browser",
      "name": "InitializeLinkDecorationTask",
      "args": [
        {
          "browser": "Browser",
          "cookie": "cookie",
          "storage": "sessionStorage",
          "whiteListedDomains": [
            "https://www.straitstimes.com/",
            "https://www.businesstimes.com.sg/",
            "https://www.zaobao.com.sg/",
            "https://www.beritaharian.sg/",
            "https://www.tamilmurasu.com.sg/",
            "https://www.thinkchina.sg/",
            "https://eshop.crsm.dev.ems.sphdigital.com.sg/",
            "https://uattmp.eshop4-cms.sph.com.sg/",
            "https://contest.sph.com.sg/",
            "http://localhost",
            "http://vanilla-rig",
            "https://contest-frontend-uat.sph.com.sg",
            "https://subscribe.sph.com.sg",
            "https://www.sphmagazines.com.sg",
            "https://www.femalemag.com.sg",
            "https://www.iconsingapore.com",
            "https://forums.hardwarezone.com.sg",
            "https://www.hardwarezone.com.sg",
            "https://www.harpersbazaar.com.sg"
          ]
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "InitializeHighwayTokenTask",
      "args": [
        {
          "highwayToken": "HighwayToken"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "InitializeTokenManagerTask",
      "args": [
        {
          "storage": "sessionStorage"
        }
      ],
      "subscriptions": ["InitializeLinkDecorationTask"]
    },
    {
      "plugin": "core",
      "name": "SendToDestinationTask",
      "identifier": "sendToBeaconTask",
      "args": ["beacon"]
    },
    {
      "plugin": "core",
      "name": "TransformTask",
      "identifier": "transformPauseTask",
      "args": [["changePauseToUnload"]]
    },
    {
      "plugin": "core",
      "name": "EventRouteTask",
      "args": [
        {
          "default": "sendToBufferTask",
          "routes": {
            "pause": "transformPauseTask"
          }
        }
      ],
      "subscriptions": ["TransformTask"]
    },
    {
      "plugin": "core",
      "name": "TransformTask",
      "args": [
        [
          "applyClickSchema",
          "applyAdClickSchema",
          "applyAdImpressionSchema",
          "applyElementVisibleSchema",
          "applyGTMDataLayerSchema",
          "applyViewTimeSchema",
          "applyScrollDepthSchema",
          "addNeuronData",
          "addArticleMetadata",
          "addUTMMetadata",
          "addDeviceMetadata",
          "addIdentity",
          "addViewId",
          "addVisitId",
          "addIncomingNeuronId",
          "addPermutive"
        ]
      ],
      "subscriptions": [
        "TrackClickTask",
        "TrackScrollDepthTask",
        "TrackCavaiAdsTask",
        "TrackElementVisibleTask",
        "trackGTMVirtualPageViewStep",
        "SendPermutiveTask",
        "SendUnfocusedPageOpenTask",
        "TrackEP2Task",
        "TrackAdClickTask",
        "TrackAdViewTask"
      ]
    },
    {
      "plugin": "core",
      "name": "TransformTask",
      "identifier": "transformOnUnloadTask",
      "args": [
        [
          "applyViewTimeSchema",
          "addNeuronData",
          "addArticleMetadata",
          "addUTMMetadata",
          "addDeviceMetadata",
          "addIdentity",
          "addViewId",
          "addVisitId",
          "addIncomingNeuronId",
          "addPermutive",
          "transformEventToPayload",
          "flushSourceToPayload",
          "applyToPayloadEvents",
          "applyHighwayPayloadSchema"
        ]
      ],
      "subscriptions": ["TrackUnloadTask"]
    },
    {
      "plugin": "core",
      "name": "TransformTask",
      "identifier": "transformOnTokenInitTask",
      "args": [
        [
          "addNeuronData",
          "addArticleMetadata",
          "addUTMMetadata",
          "addDeviceMetadata",
          "addIdentity",
          "addViewId",
          "addVisitId",
          "addIncomingNeuronId",
          "addPermutive",
          "transformEventToPayload",
          "applyHighwayPayloadSchema"
        ]
      ],
      "subscriptions": ["InitializeHighwayTokenTask"]
    },
    {
      "plugin": "browser",
      "name": "TimerTask",
      "identifier": "timer",
      "args": [
        {
          "browser": "Browser",
          "intervalMs": [10000, 250]
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackClickTask",
      "args": [
        {
          "browser": "Browser",
          "selector": "a,button"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackAdViewTask",
      "args": [
        {
          "browser": "Browser",
          "selector": "[id^='dfp-ad-']"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackAdClickTask",
      "args": [
        {
          "browser": "Browser",
          "selector": "[id^='dfp-ad-']"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackLoadTask",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackPauseTask",
      "args": [
        {
          "viewtimeInitialTimestamp": "ViewtimeInitialTimestamp"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackUnloadTask",
      "args": [
        {
          "viewtimeInitialTimestamp": "ViewtimeInitialTimestamp"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackResumeTask"
    },
    {
      "plugin": "browser",
      "name": "SendUnfocusedPageOpenTask",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackScrollDepthTask",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "DataLayer",
      "args": [
        {
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "browser",
      "name": "TrackGTMDataLayerTask",
      "args": [
        {
          "dataLayer": "DataLayer",
          "dataLayerEvents": {
            "eShop": [
              "eShopCustomEvent",
              "ec.productView",
              "ec.productClick",
              "ec.addToCart",
              "ec.checkout",
              "ec.subscribeSuccess",
              "ec.subscribeFailed",
              "ec.existingSubscriber",
              "ec.dummyPageLoaded"
            ],
            "mySPH": [
              "LoginPV",
              "LoginEvent",
              "SigninFB",
              "mysphCustomEvent",
              "SigninGoogle",
              "mysphCustomPageView",
              "mysphga4CustomPageView",
              "LoginForgotPasswordLink",
              "ForgotPasswordPV",
              "ForgotPasswordSendEmail",
              "ForgotPasswordBackToLogin",
              "ForgotPasswordConfirmPV",
              "ForgotPasswordConfirmResendLink",
              "ForgotPasswordConfirmContinueReading",
              "ForgotPasswordConfirmBacktoLogin",
              "ResetPasswordExpiredPV",
              "ResetPasswordExpiredEvent",
              "ResetPasswordExpiredBacktoLogin",
              "ResetPasswordPV",
              "ResetPasswordEvent",
              "ResetPasswordConfirmPV",
              "ResetPasswordContinuetoLogin",
              "UpdatePasswordPV",
              "UpdatePasswordEvent",
              "UpdatePasswordConfirmPV",
              "UpdatePasswordConfirmContinueReading",
              "ConcurrencyPV",
              "ConcurrencyLogout",
              "ConcurrencyCancel",
              "Logout",
              "SignupNow",
              "SignupNameEmail",
              "SignupNameEmailNext",
              "SingleSignupNameEmail",
              "SignupCaptcha",
              "SignupCaptchaSignup",
              "NormalSignupSuccess",
              "NormalSignupSuccessContinue",
              "SignupGoogle",
              "SignupFacebook",
              "SocialSignupSuccess",
              "SocialSignupSuccessContinue",
              "ExpiredLink",
              "ExpiredLinkResendEmail",
              "EmailVerifiedSuccess",
              "ResendVerificationEmail",
              "ResendVerificationEmailSend",
              "ResendVerificationEmailConfirmed",
              "ResendEmailVerifiedSuccess",
              "SocialExistingAccountVerified",
              "SocialExistingAccountVerifiedContinue",
              "SocialExistingAccountUnVerified",
              "SocialExistingAccountUnVerifiedEvent",
              "SocialExistingAccountUnVerifiedContinue",
              "SocialAccountLinked",
              "SocialAccountLinkedContinue",
              "SocialTokenError"
            ],
            "virtualload": ["virtual_pageview", "virtual_pv", "infinite_scroll"]
          },
          "ignorePatterns": ["^gtm\\..*", "sendid"]
        }
      ],
      "subscriptions": ["timer"]
    },
    {
      "plugin": "browser",
      "name": "TrackElementVisibleTask",
      "args": [
        {
          "selector": "div[id^='sph_cdp_'], div[id^='dfp-ad-']",
          "browser": "Browser"
        }
      ]
    },
    {
      "plugin": "cavaiads",
      "name": "TrackCavaiAdsTask",
      "args": ["sessionStorage"]
    },
    {
      "plugin": "browser",
      "name": "SendPermutiveTask",
      "args": ["localStorage"]
    },
    {
      "plugin": "core",
      "name": "DispatchPayloadTask",
      "args": [
        {
          "source": "buffer"
        }
      ],
      "subscriptions": ["timer"]
    },
    {
      "plugin": "core",
      "name": "TransformTask",
      "identifier": "transformPayloadTask",
      "args": [
        [
          "transformEventToPayload",
          "applyToPayloadEvents",
          "applyHighwayPayloadSchema",
          "attachHighwayTokenCallback"
        ]
      ],
      "subscriptions": ["DispatchPayloadTask"]
    },
    {
      "plugin": "core",
      "name": "SendPayloadTask",
      "identifier": "sendToHighwayWithPostTask",
      "args": ["post"],
      "subscriptions": ["transformPayloadTask", "transformOnTokenInitTask"]
    },
    {
      "plugin": "core",
      "name": "SendPayloadTask",
      "identifier": "sendToHighwayWithBeaconTask",
      "args": ["beacon"],
      "subscriptions": ["transformOnUnloadTask"]
    },
    {
      "plugin": "browser",
      "name": "TrackVirtualPageViewTask",
      "identifier": "trackGTMVirtualPageViewStep",
      "args": [
        {
          "patterns": ["virtual_pageview", "virtual_pv", "infinite_scroll"],
          "viewtimeInitialTimestamp": "ViewtimeInitialTimestamp",
          "cache": "Cache",
          "viewId": "ViewId"
        }
      ],
      "subscriptions": ["TrackGTMDataLayerTask"]
    },
    {
      "plugin": "browser",
      "name": "TrackEP2Task",
      "args": [
        {
          "browser": "Browser",
          "viewId": "ViewId",
          "cache": "Cache",
          "viewtimeInitialTimestamp": "ViewtimeInitialTimestamp"
        }
      ]
    }
  ]
}
